import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Link } from 'gatsby';
import { CheckBox, Ticket, ChevronRight } from 'akar-icons';

const SuccessPage = () => {
    return (
        <Layout>
            <Seo title="Success" description="Dawn Projects Ticket System" />
            <div className="wrap">
                <div
                    className="ticketform smallpad"
                    data-sal="slide-down"
                    data-sal-duration="500"
                    data-sal-easing="ease-in-out-quart"
                >
                    <div className="formitem ticketsuccess">
                        <CheckBox size={24} />
                        <span>Success - your ticket has been created.</span>
                        <p>
                            The ticket information is listed below. Please check
                            back often for status messages.
                        </p>
                    </div>
                </div>
                <div
                    className="tickets"
                    data-sal="slide-down"
                    data-sal-duration="500"
                    data-sal-easing="ease-in-out-quart"
                >
                    <Link
                        to="/"
                        data-sal="slide-down"
                        data-sal-duration="500"
                        data-sal-easing="ease-in-out-quart"
                    >
                        <span className="ticket">
                            <span className="ticket__top">
                                <h3>Ticket No. 1002</h3>
                                <small>
                                    Open <Ticket size={20} />
                                </small>
                                <p>
                                    Possible Safety Hazard. Loose cabling found
                                    in the back office area.
                                </p>
                            </span>
                            <span className="ticket__bot">
                                <em>View Ticket</em>
                                <ChevronRight size={24} />
                            </span>
                        </span>
                    </Link>
                </div>
            </div>
        </Layout>
    );
};

export default SuccessPage;
